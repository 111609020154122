import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Ratings = lazy(() => import('../container/ratings'));
const ModulesRatingStatistics = lazy(() => import('../container/ratings/overview/ModulesRatingStatistics'));
const SectionsRatingStatistics = lazy(() => import('../container/ratings/overview/SectionsRatingStatistics'));
const LecturesRatingStatistics = lazy(() => import('../container/ratings/overview/LecturesRatingStatistics'));
const SingleLectureRatingStatistics = lazy(() => import('../container/ratings/overview/SingleLectureRatingStatistics'));
const AllSectionsRatingStatistics = lazy(() => import('../container/ratings/overview/AllSectionsRatingStatistics'));
const AllLecturesRatingStatistics = lazy(() => import('../container/ratings/overview/AllLecturesRatingStatistics'));

const RatingsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Ratings} />
      <Route
        path={`${path}/modules-statistics/:moduleId/sections-statistics/:sectionId/lectures-statistics/:subjectId`}
        component={SingleLectureRatingStatistics}
      />
      <Route
        path={`${path}/modules-statistics/:moduleId/sections-statistics/:sectionId/lectures-statistics`}
        component={LecturesRatingStatistics}
      />
      <Route path={`${path}/modules-statistics/:moduleId/sections-statistics`} component={SectionsRatingStatistics} />
      <Route path={`${path}/lectures-statistics`} component={AllLecturesRatingStatistics} />
      <Route path={`${path}/sections-statistics`} component={AllSectionsRatingStatistics} />
      <Route path={`${path}/modules-statistics`} component={ModulesRatingStatistics} />
    </Switch>
  );
};

export default RatingsRoutes;
