/* eslint-disable import/no-cycle */
import { APIService } from './ApiService';

export const getAllRatings = (page, limit, search, isWithComment, selectRating) =>
  APIService.get(
    `ratings/lectures?page=${page}&limit=${limit}&search=${search}&isWithComment=${isWithComment}&selectRating=${selectRating}`,
  );

export const getModulesStats = ({ courseId, fromDate, toDate }) => {
  return APIService.get(
    `ratings/lectures/modules-stats?${courseId ? `courseId=${courseId}&` : ''}${
      fromDate ? `fromDate=${fromDate}&` : ''
    }${toDate ? `toDate=${toDate}` : ''}`,
  );
};

export const getAllSectionsStats = ({ courseId, fromDate, toDate }) => {
  return APIService.get(
    `ratings/lectures/all-sections-stats?${courseId ? `courseId=${courseId}&` : ''}${
      fromDate ? `fromDate=${fromDate}&` : ''
    }${toDate ? `toDate=${toDate}` : ''}`,
  );
};

export const getAllLecturesStats = ({ fromDate, toDate }) => {
  return APIService.get(
    `ratings/lectures/all-lectures-stats?${fromDate ? `fromDate=${fromDate}&` : ''}${toDate ? `toDate=${toDate}` : ''}`,
  );
};

export const getSectionsStats = ({ moduleId, fromDate, toDate }) => {
  return APIService.get(
    `ratings/lectures/sections-stats-by-module?${moduleId ? `moduleId=${moduleId}&` : ''}${
      fromDate ? `fromDate=${fromDate}&` : ''
    }${toDate ? `toDate=${toDate}` : ''}`,
  );
};

export const getLecturesStats = ({ sectionId, fromDate, toDate }) => {
  return APIService.get(
    `ratings/lectures/lectures-stats-by-section?${sectionId ? `sectionId=${sectionId}&` : ''}${
      fromDate ? `fromDate=${fromDate}&` : ''
    }${toDate ? `toDate=${toDate}` : ''}`,
  );
};

export const getSingleLectureStats = ({ subjectId, fromDate, toDate, contentVersion }) => {
  return APIService.get(
    `ratings/lectures/single-lecture-stats?${subjectId ? `sectionId=${subjectId}&` : ''}${
      fromDate ? `fromDate=${fromDate}&` : ''
    }${toDate ? `toDate=${toDate}` : ''}${contentVersion ? `&contentVersion=${contentVersion}` : ''}`,
  );
};

export const getSubjectContentVersions = subjectId =>
  APIService.get(`ratings/lectures/content-versions?subjectId=${subjectId}`);

export const postLectureRate = payload => APIService.post(`ratings/lectures`, { ...payload });

export const getLectureInformations = subjectId => APIService.get(`ratings/lectures/${subjectId}`);

export const getSubjectRatings = ({ from, to, sortingType, page, limit, contentVersion, subjectId }) => {
  return APIService.get(
    `ratings/lectures/subject-ratings?${from ? `fromDate=${from}&` : ''}${to ? `toDate=${to}&` : ''}${
      sortingType ? `sortingType=${sortingType}&` : ''
    }${page ? `page=${page}&` : ''}${limit ? `limit=${limit}&` : ''}${
      contentVersion ? `contentVersion=${contentVersion}&` : ''
    }${subjectId ? `subjectId=${subjectId}` : ''}`,
  );
};
