import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Products = lazy(() => import('../container/products/'));
const ProductDetails = lazy(() => import('../container/products/overview/ProductDetails'));
const ProductComboDiscounts = lazy(() => import('../container/products/overview/ProductComboDiscounts'));

const ProductsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Products} />
      <Route exact path={`${path}/combo-discounts`} component={ProductComboDiscounts} />
      <Route exact path={`${path}/:productId`} component={ProductDetails} />
    </Switch>
  );
};

export default ProductsRoutes;
