/* eslint-disable import/no-cycle */
import axios from 'axios';
import { APIService } from './ApiService';

// MATERIALS

export const getMaterials = () => APIService.get('materials');

export const addMaterial = payload => APIService.post('materials', { ...payload });

export const getMaterialsByStudentId = userId => APIService.get(`materials/users/${userId}`);

export const assignMaterialToStudent = payload => APIService.post('materials/users/sections', { ...payload });

export const getVerifiableMaterials = isUseCache =>
  APIService.get(`materials/statuses?type=VERIFIABLE&useCache=${isUseCache}`);

export const getAssignedVerifiableMaterials = ({ selectedDate, isUseCache }) =>
  APIService.get(`materials/statuses/assigned?type=VERIFIABLE&selectedDate=${selectedDate}&useCache=${isUseCache}`);

export const getOverdueVerifiableMaterials = ({ selectedDate, selectedSectionName }) =>
  APIService.get(
    `materials/statuses/overdue?type=VERIFIABLE&selectedDate=${selectedDate}&selectedSectionName=${selectedSectionName.replace(
      /\s/g,
      '',
    )}`,
  );

export const getRevievedExercises = () => APIService.get('materials/statuses/reviewed');

export const getMaterialStats = payload =>
  APIService.get(`materials/${payload.materialId}/courses/${payload.courseId}/sections/statuses`);

// SECTIONS

export const getSectionsByMaterialId = materialId => APIService.get(`materials/${materialId}/sections/`);

export const addSection = payload => APIService.post('materials/sections', { ...payload });

export const editSection = payload => APIService.put('materials/sections', { ...payload });

// SUBJECTS

export const getSubject = payload => APIService.get(`materials/${payload.materialId}/subjects/${payload.subjectId}`);

export const getAllSubjectsInSectionBySubjectId = payload =>
  APIService.get(`materials/${payload.materialId}/sections/subjects/${payload.subjectId}`);

export const addSubjectToSection = payload => APIService.post('materials/subjects', { ...payload });

export const editSubject = payload => APIService.put('materials/subjects', { ...payload });

export const getStudentActivityData = () => APIService.get('materials/activity');

export const getAssignedNegativePoints = (userId, courseId, subjectId) =>
  APIService.get(`materials/users/${userId}/courses/${courseId}/subjects/${subjectId}/negative-points`);

export const addFilesToSubjectContent = payload => {
  const role = payload.role === 'SENIOR-TEACHER' ? 'teacher' : payload.role.toLowerCase();

  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}${role}/materials/subjects/files`,
    data: payload.formData,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${payload.accessToken}` },
  });
};

export const createNewSubjectVersion = payload => APIService.post('materials/subjects/versions', { ...payload });

// COMMENTS

export const getComments = payload =>
  APIService.get(
    payload.userRole === 'STUDENT'
      ? `materials/courses/${payload.courseId}/subjects/${payload.subjectId}/comments`
      : `materials/users/${payload.userId}/courses/${payload.courseId}/subjects/${payload.subjectId}/comments`,
  );

export const addComment = payload => APIService.post(`materials/users/subjects/comments`, { ...payload });

export const addFilesToComment = payload => {
  const role = payload.role === 'SENIOR-TEACHER' ? 'teacher' : payload.role.toLowerCase();

  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}${role}/materials/users/subjects/comments/${payload.commentId}/files`,
    data: payload.formData,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${payload.accessToken}` },
  });
};

export const downloadCommentFile = async payload => {
  const role = payload.role === 'SENIOR-TEACHER' ? 'teacher' : payload.role.toLowerCase();

  const response = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}${role}/materials/users/subjects/comments/files/${
      payload.key
    }-${payload.originalFileName.toUpperCase()}`,
    responseType: 'blob',
    headers: { Authorization: `Bearer ${payload.accessToken}` },
  });
  const href = URL.createObjectURL(response.data);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${payload.originalFileName.toLowerCase()}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const downloadSubjectFile = async payload => {
  const role = payload.role === 'SENIOR-TEACHER' ? 'teacher' : payload.role.toLowerCase();

  const response = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}${role}/materials/users/subjects/files/SUBJECTFILE-${
      payload.key
    }-${payload.originalFileName.toUpperCase()}`,
    responseType: 'blob',
    headers: { Authorization: `Bearer ${payload.accessToken}` },
  });
  const href = URL.createObjectURL(response.data);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${payload.originalFileName.toLowerCase()}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
// STATUS

export const getStatuses = payload =>
  APIService.get(
    payload.userRole === 'STUDENT'
      ? `materials/courses/${payload.courseId}/subjects/${payload.subjectId}/statuses`
      : `materials/users/${payload.userId}/courses/${payload.courseId}/subjects/${payload.subjectId}/statuses`,
  );

export const addStatus = payload => APIService.post(`materials/users/subjects/statuses`, { ...payload });

export const addStatusAsStudent = payload => APIService.post(`materials/subjects/statuses`, { ...payload });

export const unwatchLecture = payload => APIService.post('materials/subjects/statuses/unwatch', { ...payload });

// SUBJECT ANSWER

export const getSubjectAnswer = payload =>
  APIService.get(`materials/subjects/${payload.subjectId}/answers/${payload.contentVersionNo}`);

export const editSubjectAnswer = payload => APIService.put(`materials/subjects/answers`, { ...payload });

export const getExcercisesDailyStats = payload =>
  APIService.get(`materials/statuses/stats?type=DAILY&startDate=${payload.startDate}&endDate=${payload.endDate}`);

export const getExcercisesMonthlyStats = payload =>
  APIService.get(`materials/statuses/stats?type=MONTHLY&startDate=${payload.startDate}&endDate=${payload.endDate}`);

export const getTeachersExcercisesDailyStats = payload =>
  APIService.get(
    `materials/statuses/stats/teachers?type=DAILY&startDate=${payload.startDate}&endDate=${payload.endDate}`,
  );

export const getTeachersExcercisesMonthlyStats = payload =>
  APIService.get(
    `materials/statuses/stats/teachers?type=MONTHLY&startDate=${payload.startDate}&endDate=${payload.endDate}`,
  );

export const addFilesToSubjectAnswer = payload => {
  const role = payload.role === 'SENIOR-TEACHER' ? 'teacher' : payload.role.toLowerCase();

  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}${role}/materials/subjects/answers/files`,
    data: payload.formData,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${payload.accessToken}` },
  });
};

// STUDENT DASHBOARD STATS

export const getDashboardExercisesStudentStats = payload => APIService.get(`materials/courses/${payload}/stats`);

// ASSIGNED SUBJECTS TO TEACHERS

export const getAssignedSubjectsToTeachers = () => APIService.get(`materials/subjects/teachers/assigned`);

export const assignSubjectsToTeacher = payload =>
  APIService.post(`materials/subjects/teachers/assigned`, { ...payload });

export const assignMultipleSubjectsToTeacher = payload =>
  APIService.post(`materials/subjects/teachers/assigned/multiple`, { ...payload });

export const removeMultipleAssignedSubjectToTeacher = payload =>
  APIService.delete(`materials/subjects/teachers/assigned/multiple`, { data: { ...payload } });

export const removeAssignedSubjectToTeacher = payload =>
  APIService.delete(`materials/subjects/teachers/assigned`, {
    data: { ...payload },
  });

export const assignDateToTeacher = payload =>
  APIService.post(`materials/subjects/teachers/assigned/dates`, { ...payload });

export const removeAssignedDateToTeacher = payload =>
  APIService.delete(`materials/subjects/teachers/assigned/dates`, {
    data: { ...payload },
  });

export const addNewExerciseQueueJob = payload => APIService.post(`/exercises/jobs`, { ...payload });

export const rateExerciseQueueJob = payload => APIService.post(`/exercises/jobs/rate`, { ...payload });

export const getExerciseQueueJobs = ({ pageNo, limit, status, expectedSolutionType, search }) =>
  APIService.get(
    `/exercises/jobs?page=${pageNo}&limit=${limit}&status=${status}&expectedSolutionType=${expectedSolutionType}&search=${search ||
      ''}`,
  );

export const getExerciseQueueJobsStats = () => APIService.get(`/exercises/jobs/stats`);

export const getDetailedExerciseQueueJobsStats = (startDate, endDate) =>
  APIService.get(`/exercises/jobs/detailed-stats`, { params: { startDate, endDate } });

export const sendToQueue = payload => APIService.post(`/exercises/jobs/retry`, { ...payload });

export const getAllSubjectAvailableToAssignToTeacher = () => APIService.get(`materials/subjects/teachers/all`);

export const getAllSubjectAvailableToAssignToTeacherV2 = () => APIService.get(`materials/subjects/teachers/all/v2`);

export const getCoursePoints = ({ courseId }) => APIService.get(`points/courses/${courseId}`);

export const getAllSubjectPoints = ({ subjectId }) => APIService.get(`points/subjects/${subjectId}`);

export const getAllNegativePoints = (page, limit, search) =>
  APIService.get(`materials/negative-points/all?page=${page}&limit=${limit}&search=${search}`);

export const getLastTeachersComments = ({ page, limit, search, teacherId, startDate, endDate }) =>
  APIService.get(
    `materials/comments/last?page=${page}&limit=${limit}&search=${search}&teacherId=${teacherId}&startDate=${startDate}&endDate=${endDate}`,
  );
