// eslint-disable-next-line import/no-cycle
import { APIService } from './ApiService';

export const importQuestions = file => APIService.post('question-database/import-questions', file);

export const addQuestion = payload => APIService.post('question-database/question', { ...payload });

export const addSection = payload => APIService.post('question-database/section', { ...payload });

export const addSource = payload => APIService.post('question-database/source', { ...payload });

export const getSections = () => APIService.get('question-database/sections');

export const getLockedSections = () => APIService.get('question-database/sections/locked');

export const getSources = () => APIService.get('question-database/sources');

export const editSection = (sectionId, section) =>
  APIService.put(`question-database/section/${sectionId}`, { ...section });

export const editQuestion = (questionId, question) =>
  APIService.put(`question-database/question/${questionId}`, { ...question });

export const editSource = (sourceId, source) => APIService.put(`question-database/source/${sourceId}`, { ...source });

export const addAnswer = (questionId, answer) =>
  APIService.post(`question-database/question/${questionId}/answer`, { ...answer });

export const editAnswer = (answerId, answer) => APIService.put(`question-database/answer/${answerId}`, { ...answer });

export const getAnswers = questionId => APIService.get(`question-database/question/${questionId}/answers`);

export const getAdminQuestions = ({ page, limit, search, filter }) => {
  return APIService.get('question-database/questions', { params: { page, limit, search, filter } });
};

export const addMultipleQuestions = questions => APIService.post('question-database/questions', { ...questions });

export const getQuestionsToVerifyStatistics = () => APIService.get('question-database/questions/verify/stats');

export const getQuestionToVerify = (section, source, difficulty) =>
  APIService.get('question-database/questions/verify', { params: { section, source, difficulty } });

export const verifyQuestion = (questionId, notes, status) =>
  APIService.put(`question-database/question/${questionId}/verify`, { notes, status });

export const convertQuestions = () => APIService.put('question-database/questions/convert');

export const addSectionRequirement = (sectionId, requirement) =>
  APIService.post(`question-database/section/${sectionId}/requirement`, { ...requirement });

export const removeRequirement = (sectionId, requirementId) =>
  APIService.delete(`question-database/section/${sectionId}/requirement/${requirementId}`);

export const getQuestionsForStudent = ({ sections, difficulties, sources, statuses, page, timestamp }) =>
  APIService.get('question-database/questions', {
    params: { sections, difficulties, sources, statuses, page, timestamp },
  });

export const resetQuestionStatistics = ({ sections, difficulties, sources, statuses }) =>
  APIService.post('question-database/questions/statistics/reset', { sections, difficulties, sources, statuses });

export const getQuestionStatistics = ({ sections, difficulties, sources, statuses }) =>
  APIService.get('question-database/questions/statistics', { params: { sections, difficulties, sources, statuses } });

export const sendSelectedAnswer = (questionId, answersIds) =>
  APIService.post(`question-database/question/${questionId}/answer`, { answers: answersIds });

export const addQuestionToFavourites = questionId =>
  APIService.post(`question-database/question/${questionId}/favorite`);
