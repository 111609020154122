/* eslint-disable import/no-cycle */
import { APIService } from './ApiService';

export const getAllProducts = () => APIService.get('products');

export const getProductDetail = productId => APIService.get(`products/${productId}`);

export const addProduct = payload => APIService.post('products', { ...payload });

export const addProductItem = payload => APIService.post('products/items', { ...payload });

export const getProductDetailsAsStudent = payload =>
  APIService.get(`products/courses/${payload.courseId}/courseMaterials/${payload.courseMaterialId}`);

export const getPackagesShopProducts = payload => APIService.get(`/products/courses/${payload.courseId}`);

export const getAssignedProducts = () => APIService.get('products/assigned');

export const getProductComboDiscounts = () => APIService.get('products/combo-discounts');

export const addProductComboDiscount = payload => APIService.post('products/combo-discounts', { ...payload });

export const editProductComboDiscount = payload => APIService.put(`products/combo-discounts`, { ...payload });
